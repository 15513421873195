
import { mapGetters, mapActions } from 'vuex';
import i18n from '@/vueI18n';
import FsLightbox from 'fslightbox-vue';

export default {
  components: {
    FsLightbox,
  },
  data() {
    return {
      slideIndex: 0,
      slide: 0,
      toggler: false,
      modalImages: [],
      captionArray: [],
    };
  },
  computed: {
    ...mapGetters({
      isMobile: 'layout/isMobile',
      record: 'library/record',
      rows: 'library/rows',
      current: 'branch/view/branch',
      loading: 'library/loading',
    }),
    isRTL() {
      return i18n.locale == 'ar';
    },
    options() {
      return {
        rewind: true,
        gap: '1.25rem',
        perPage: 2,
        breakpoints: {
          640: {
            perPage: 1,
            gap: '1rem',
          },
          480: {
            perPage: 1,
            gap: '1rem',
          },
        },
      };
    },
    language() {
      return i18n.locale;
    },
  },
  watch: {
     current() {
       this.doFetch();
    },
  },
  methods: {
    openImageModal(index) {
      this.toggler = !this.toggler;
      this.slideIndex = index;
    },
    i18n(key, args) {
      return this.$t(key, args);
    },
    getFirstWordPresenter(description) {
      if (this.isRTL) {
        return this.getFirstWord(description['ar']);
      } else {
        return this.getFirstWord(description['en']);
      }
    },
    getFirstWord(str) {
      let spaceIndex = str.indexOf(' ');
      return spaceIndex === -1
        ? str
        : str.substr(0, spaceIndex);
    },
    getSecondWordPresenter(description) {
      if (this.isRTL) {
        return this.getSecondWord(description['ar']);
      } else {
        return this.getSecondWord(description['en']);
      }
    },
    getSecondWord(str) {
      return str.split(/\s+/).slice(1, 2).toString();
    },
    removeFirstTwoWord(description) {
      let str = this.isRTL
        ? description['ar']
        : description['en'];
      str = str.split(/\s+/).slice(2, str.length);
      return str.join(' ');
    },
    namePresenter(value) {
      return this.isRTL ? value['ar'] : value['en'];
    },
    ...mapActions({
      doFind: 'library/doFind',
      doFetch: 'library/doFetch',
    }),
  },

  async created() {
    // await this.doFind() // this.$route.params.id
    await this.doFetch(); //this.current
    console.log(this.laoding);
  },
};
